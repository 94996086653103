import {
  FormControl as MuiFormControl,
  FormHelperText as MuiFormHelperText,
  InputLabel as MuiInputLabel,
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
  Select as MuiSelect,
  SelectChangeEvent as MuiSelectChangeEvent,
  SelectProps as MuiSelectProps,
} from '@mui/material';
import { forwardRef } from 'react';

export interface SelectProps<T> extends MuiSelectProps<T> {
  helperText?: string;
  changeEvent?: (v: T) => void;
}

export const Select = forwardRef(
  <T extends unknown>(
    {
      error,
      disabled,
      required,
      variant = 'outlined',
      fullWidth,
      helperText,
      ...rest
    }: SelectProps<T>,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const handleChange = (event: MuiSelectChangeEvent<T>) => {
      rest.changeEvent?.(event.target.value as T);
    };

    return (
      <MuiFormControl
        disabled={disabled}
        required={required}
        variant={variant}
        fullWidth={fullWidth}
        error={error}
        ref={ref}
      >
        {rest.label && <MuiInputLabel id={rest.labelId}>{rest.label}</MuiInputLabel>}
        <MuiSelect labelId={rest.labelId} onChange={rest.onChange || handleChange} {...rest} />
        {helperText && <MuiFormHelperText>{helperText}</MuiFormHelperText>}
      </MuiFormControl>
    );
  },
);

export const MenuItem = ({ ...rest }: MuiMenuItemProps) => <MuiMenuItem {...rest} />;
